@tailwind base;
@tailwind components;
@tailwind utilities;

.map-container {
  height: 100vh;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;

}


.sidebar-debug {
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  border-radius: 4px;
}


.titleBox {
 /* background-color: #212121ee;
  color: #fff;*/
  background-color: #41ffceee;
  color: #000000;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  width:fit-content;
  border-radius: 4px;  font-size: 16px;
}

.outsideTitle {
  /* background-color: #212121ee;
   color: #fff;*/
   color: #ffffff;
   font-family: monospace;
   z-index: 1;
   position: absolute;
   top: 0;
   left: 0;
 }

 .banned-box-text {
  max-width: 85%
 }

 .mejiascrollbar::-webkit-scrollbar-thumb {
     
  border-radius: 8px;
  border: 4px solid #717171;
  background-clip: content-box;
  background-color: #717171;  
}

.mejiascrollbar {
scroll-margin-top: 2px;
scroll-margin-bottom: 2px;
scroll-margin-right: 3px;
}

