@tailwind base;
@tailwind components;
@tailwind utilities;



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mapbox-logo{
  display: none !important;
}

.bg-neon {
  background-color: #41ffca;
}

.text-neon {
  color: #41ffca;
}

.customscrollbar::-webkit-scrollbar-thumb {
     
  border-radius: 8px;
  border: 4px solid #717171;
  background-clip: content-box;
  background-color: #717171;  
}

.customscrollbar {
scroll-margin-top: 2px;
scroll-margin-bottom: 2px;
scroll-margin-right: 3px;
}



  .mapboxgl-ctrl-geocoder--input {
    background-color: #171717;
    border-radius: 16px;
    color: white;
  }

  .geocoder .mapboxgl-ctrl-geocoder--input {
    font-size: 16px;
    height: 40px;
  }

  .geocoder .mapboxgl-ctrl-geocoder--icon-search {
    top: 7px;
  }


  .mapboxgl-ctrl-geocoder--input:focus {
    background-color: #171717;
    border-radius: 16px;
    color: white;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    color: white;
    fill: #fafafa;
  }

 .suggestions-wrapper .suggestions {
    background-color: #171717;
    color: #fafafa;
  }

  .suggestions a:hover {
    background-color: #2a2a2a;
    color: #ffffff;
  }

  .suggestions-wrapper .mapboxgl-ctrl-geocoder--button {
    background-color: transparent;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    fill: #fafafa;
    background: transparent;
  }

  .active > a {
    background: #2a2a2a !important;
  }

  .mapboxgl-ctrl-geocoder--icon-close:hover {
    fill: white;
    background: transparent;
  }

  .suggestions {
    background-color: #171717;
    color: #fafafa;
  }

  .mapboxgl-ctrl-geocoder--input:focus-visible {
    border-style: solid !important;
  }

  .active .mapboxgl-ctrl-geocoder--suggestion {
    background-color: #2a2a2a;
    color: #ffffff;
  }

  .active a {
    background-color: #2a2a2a;
    color: #ffffff;
  }

  .geocoder input:focus {
    outline-style: none;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    margin-top: 2px;
  }

.suggestions-wrapper .suggestions .active {
  background-color: #262626;
}

  .geocoder .mapboxgl-ctrl-geocoder {
    max-width: 80vw;
    margin-left: 6px;
    margin-right: 4px;
    margin-top: 8px;
  }

  .mapboxgl-ctrl-geocoder {
    background-color: #525252;
    border-radius: 16px;
  }

  .suggestions-wrapper .mapboxgl-ctrl-geocoder--suggestion-address {
    color: #fafafa !important;
  }

  .suggestions-wrapper .mapboxgl-ctrl-geocoder--suggestion-title {
    color: #fafafa !important;
  }

  .mapboxgl-ctrl-geocoder--button {
    background: transparent;
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    top: 2px !important;
    fill: #e2e8f0 !important;
  }

  .mapboxgl-ctrl-geocoder--icon-loading:first-child {
 
    /*fill: #e2e8f0 !important;*/
  }

  .mapboxgl-ctrl-geocoder--icon-loading:last-child {
   /* fill: #64748b !important;*/
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    top: 2px !important;
  }

  .mapboxgl-ctrl-logo {
    display: none
  }
  
  .mapboxgl-ctrl-bottom-right {
    display: none
  }
